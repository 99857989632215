<template>
    <div>
        <Header :id="id"></Header>
        <div class="extit">{{ exhibitionName }}</div>
        <div class="exlogo">
            <div class="eximg">
                <img :src="image" alt="">
            </div>
        </div>
        <div class="exinfo">
            <div class="info-item" v-for="(item,index) in list" :key="index" :v-if="item.zoneName == letter[index].name">
                <div class="letter">
                    <div class="letterup" :style="{background: letter[index].color}">{{letter[index].name}}</div>
                    <div class="letterdown"><a-icon type="caret-down" :style="{color: letter[index].color}" /></div>
                </div>
                 <div class="info-title">{{ item.categoryName }}</div>
                 <ul>
                    <li v-for="(v,k) in item.exhibitors" :key="k"><router-link :to="{ name: 'Supplier', params: { id: v.companyId, exhibitionId: id } }">{{ item.zoneName }}{{ v.nums }} {{ v.companyName }}</router-link></li>
                 </ul>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "../components/Footer";

export default {
  name: "Exhibi",
  components: {
    Footer,
    Header,
  },
  data () {
    return {
        letter: [
            {name: 'A',color: '#D56D69'},
            {name: 'B',color: '#5784A9'},
            {name: 'C',color: '#48BEA5'},
            {name: 'D',color: '#E8B63E'},
            {name: 'E',color: '#EA67A1'},
            {name: 'F',color: '#D56D69'},
            {name: 'G',color: '#5784A9'},
            {name: 'H',color: '#48BEA5'},
            {name: 'I',color: '#E8B63E'},
            {name: 'J',color: '#EA67A1'},
        ],
        id: this.$route.params.id,
        list: {
          exhibitors: []
        },
        image: '',
        exhibitionName: '',
        token: localStorage.token
    }
  },
  mounted () {
    if(this.id == 108){
      
    }else{
      if (this.token == '') {
        this.$layer.msg('请先登录');
        this.$router.go(-1)
      }
    }
    this.getList()
    this.getExhibitionDetail()
  },
  methods: {
    // 获取菜单栏信息
    getList () {
       this.$common.fetchList('/exhibition/exhibitor/position?exhibitionId='+this.id, {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          console.log(result)
          this.list = result
        }
      }).catch(() => {
        // this.$notification.error({
        //   message: '错误',
        //   description: '请求信息失败，请重试'
        // })
      })
    },
    //展商详情
    getExhibitionDetail () {
       this.$common.fetchList('/exhibition/detail/'+this.id, {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.image = result.exhibitionImage
          this.exhibitionName = result.exhibitionName
        } 
      }).catch(() => {
        // this.$notification.error({
        // message: '错误',
        // description: '请求信息失败，请重试'
        // })
      })
    },
  }
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/exhibitors.less';
</style>